import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { BallTriangle } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
// import mascotLogo from '../public/mascot_logo.png';

import './App.css';

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const [clientID, setClientID] = useState('');
  const [remainingStrips, setRemainingStrips] = useState(null);

  // Check for token in local storage on component mount
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setAccessToken(token);
      setLoggedIn(true);
      getCurrentUserData(token);
    }
  }, []);

  const login = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://potokeun-server-37cb51c38f66.herokuapp.com/admin/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password
        })
      });

      const data = await response.json();

      if (data.accessToken) {
        localStorage.setItem('accessToken', data.accessToken); // Save token to local storage
        setAccessToken(data.accessToken);
        setLoggedIn(true);
        await getCurrentUserData(data.accessToken);
      } else {
        throw new Error(data.message || "Authentication failed!");
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const logout = () => {
    setLoggedIn(false);
    setAccessToken('');
    localStorage.removeItem('accessToken'); // Remove token from local storage
  };

  const getCurrentUserData = async (token) => {
    try {
      setLoading(true);
      const response = await fetch('https://potokeun-server-37cb51c38f66.herokuapp.com/admin/current', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      console.log("data is: ", data)
      console.log(data)
      setUsername(data.username)
      setRemainingStrips(data.limitStrips - data.useCode);
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching user data.");
    }
  };

  const getCode = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://potokeun-server-37cb51c38f66.herokuapp.com/voucher/get/${username}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      const data = await response.json();
      setVoucherCode(data.voucherCode);
      setClientID(data.clientID);
    } catch (error) {
      toast.error("Error fetching the code.");
    }
    setLoading(false);
  };

  return (
    <div className="App">
      <ToastContainer />
      {loading ? (
        <div className="centered">
          <BallTriangle
            height={100}
            width={100}
            color="grey"
            ariaLabel="ball-triangle-loading"
          />
        </div>
      ) : !loggedIn ? (
        <div>
          <h2>Login</h2>
          <label>Username:</label>
          <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
          <label>Password:</label>
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
          <button onClick={login}>Login</button>
        </div>
      ) : (
        <div>
          <h2>User: {username}</h2>
          <button onClick={getCode}>Get Code</button>
          {remainingStrips != null && <p>Remaining Strips: {remainingStrips}</p>}
          {voucherCode && clientID && (
            <div className="voucher-info">
              <p><label>Voucher Code:</label> <span className="underline-text">{voucherCode}</span></p>
              <p><label>Client ID:</label> <span className="underline-text">{clientID}</span></p>
            </div>
          )}
        </div>
      )}

      {/* Footer with Logout Button */}
      {loggedIn && (
        <div>
          <button className="logout-btn" onClick={logout}>Logout</button>
        </div>
      )}
    </div>
  );
}

export default App;
